@mixin baseHalfBorder {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        right: -100%;
        left: 0;
        bottom: -100%;
        pointer-events: none;
        z-index: 1;
        transform: scale(0.5);
        transform-origin: 0% 0%;
    }
}

/**
   * 半像素边框
   */
@mixin halfPixelBorder($color: #aaa, $radius: 0px, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border: 1px $type $color;
        border-radius: $radius * 2;
    }
}

/**
   * 半像素上边框
   */
@mixin halfPixelBorderTop($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-top: 1px $type $color;
    }
}

/**
   * 半像素下边框
   */
@mixin halfPixelBorderBottom($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-bottom: 1px $type $color;
    }
}

/**
   * 半像素右边框
   */
@mixin halfPixelBorderRight($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-right: 1px $type $color;
    }
}

@mixin halfPixelBorderLeft($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-left: 1px $type $color;
        transform: scale(0.5, 1);
    }
}

@mixin arrow($color: #fff, $size: 10px, $border: 2px) {
    position: relative;
    border-top: $border solid $color;
    border-right: $border solid $color;
    width: $size;
    height: $size;
    font-size: 0px;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    display: inline-block;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

@mixin ellipsis-line($line: 2) {
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* stylelint-enable */
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

@import '../../../../../node_modules/pajk-design/es/style/themes/default.scss';
.m-specialist {
    background-image: linear-gradient(180deg, #ffffff 0%, #ffffff 57%);
    border-radius: 8px;
    margin: 0 12px 8px 12px;
    padding: 16px 0 10px 12px;
    .hd {
        padding-bottom: 12px;
        .title {
            font-size: 18px;
            line-height: 18px;
            color: #333333;
            margin-right: 8px;
        }
        .serv {
            font-size: 12px;
            color: #666666;
            span {
                color: $color-text;
                font-weight: bold;
            }
        }
        .all_btn {
            font-size: 12px;
            color: #666666;
            padding-right: 10px;
            display: inline-block;
        }
    }
}

.m-specialist-departments {
    width: fit-content;
    display: flex;
    padding-right: 0.1rem;
}

.m-specialist-dep-item {
    background: #fafafa;
    border-radius: 8px;
    height: 42px;
    width: 0.88rem;
    padding: 0 0.1rem 0 0.1rem;
    margin-right: 2px;
    margin-bottom: 2px;
    .img {
        display: block;
        width: 16px;
        height: 16px;
    }
    .title {
        overflow: hidden;
        white-space: nowrap;
        font-size: 14px;
        color: #333333;
        margin-left: 0.06rem;
        flex: 1;
        span {
            visibility: hidden;
            opacity: 0;
        }
    }
}
