@mixin baseHalfBorder {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        right: -100%;
        left: 0;
        bottom: -100%;
        pointer-events: none;
        z-index: 1;
        transform: scale(0.5);
        transform-origin: 0% 0%;
    }
}

/**
   * 半像素边框
   */
@mixin halfPixelBorder($color: #aaa, $radius: 0px, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border: 1px $type $color;
        border-radius: $radius * 2;
    }
}

/**
   * 半像素上边框
   */
@mixin halfPixelBorderTop($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-top: 1px $type $color;
    }
}

/**
   * 半像素下边框
   */
@mixin halfPixelBorderBottom($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-bottom: 1px $type $color;
    }
}

/**
   * 半像素右边框
   */
@mixin halfPixelBorderRight($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-right: 1px $type $color;
    }
}

@mixin halfPixelBorderLeft($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-left: 1px $type $color;
        transform: scale(0.5, 1);
    }
}

@mixin arrow($color: #fff, $size: 10px, $border: 2px) {
    position: relative;
    border-top: $border solid $color;
    border-right: $border solid $color;
    width: $size;
    height: $size;
    font-size: 0px;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    display: inline-block;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

@mixin ellipsis-line($line: 2) {
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* stylelint-enable */
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

@import '../../../../../node_modules/pajk-design/es/style/themes/default.scss';
.m-article-inflist {
    background: #ffffff;
    border-radius: 8px;
    margin: 0 12px 8px 12px;
    padding: 0 12px 0 12px;
    .hd {
        padding-top: 16px;
        font-size: 18px;
        color: #333333;
        font-weight: bold;
        line-height: 18px;
    }
}

.m-article-inflist-item {
    padding: 12px 0 14px 0;
    .img {
        width: 80px;
        height: 60px;
        margin-left: 14px;
        position: relative;
        img {
            width: 100%;
            height: 100%;
            display: block;
            border-radius: 8px;
        }
        .play_icon {
            position: absolute;
            top: 50%;
            left: 50%;
            background: rgba($color: #000000, $alpha: 0.5);
            width: 26px;
            height: 26px;
            margin: -13px 0 0 -13px;
            border-radius: 26px;
            &::after {
                content: "";
                display: block;
                border-top: 5px solid transparent;
                border-bottom: 5px solid transparent;
                border-left: 7px solid #fff;
                position: absolute;
                top: 50%;
                left: 50%;
                margin: -5px 0 0 -2px;
            }
        }
    }
    .title {
        font-size: 16px;
        color: #333333;
        line-height: 22px;
    }
    .subtitle {
        margin-top: 8px;
        font-size: 12px;
        color: #999999;
        line-height: 12px;
    }
}
