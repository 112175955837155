@mixin baseHalfBorder {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        right: -100%;
        left: 0;
        bottom: -100%;
        pointer-events: none;
        z-index: 1;
        transform: scale(0.5);
        transform-origin: 0% 0%;
    }
}

/**
   * 半像素边框
   */
@mixin halfPixelBorder($color: #aaa, $radius: 0px, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border: 1px $type $color;
        border-radius: $radius * 2;
    }
}

/**
   * 半像素上边框
   */
@mixin halfPixelBorderTop($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-top: 1px $type $color;
    }
}

/**
   * 半像素下边框
   */
@mixin halfPixelBorderBottom($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-bottom: 1px $type $color;
    }
}

/**
   * 半像素右边框
   */
@mixin halfPixelBorderRight($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-right: 1px $type $color;
    }
}

@mixin halfPixelBorderLeft($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-left: 1px $type $color;
        transform: scale(0.5, 1);
    }
}

@mixin arrow($color: #fff, $size: 10px, $border: 2px) {
    position: relative;
    border-top: $border solid $color;
    border-right: $border solid $color;
    width: $size;
    height: $size;
    font-size: 0px;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    display: inline-block;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

@mixin ellipsis-line($line: 2) {
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* stylelint-enable */
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

@import '../../../../../node_modules/pajk-design/es/style/themes/default.scss';
.m-seckill {
    display: flex;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 8px;
    margin: 0 12px 8px 12px;
    padding: 16px 12px 10px 12px;
}

.m-seckill-item {
    .title {
        font-size: 18px;
        color: #333333;
        line-height: 18px;
        margin-bottom: 6px;
        display: block;
    }
    .pajkd-countdown-block {
        color: $color-text;
        em {
            background-color: $color-text;
        }
    }
    .subtitle {
        height: 16px;
        line-height: 16px;
        font-size: 12px;
        color: #666666;
    }
    .con {
        width: 84px;
        height: 84px;
        background: #fafafa;
        border-radius: 8px;
        position: relative;
        margin: 8px 0 0 2px;
        .img {
            border-radius: 8px;
            width: 100%;
            height: 100%;
            display: block;
        }
        .price {
            position: absolute;
            text-align: center;
            font-weight: bold;
            bottom: 0;
            z-index: 2;
            width: 100%;
            background: #fff0ea;
            font-size: 12px;
            color: #ff6936;
            height: 20px;
            line-height: 20px;
            border-bottom-left-radius: 8px;
            border-bottom-right-radius: 8px;
        }
        .pajkd-btn-type-primary {
            background: #ff6936;
            border-radius: 12px;
            height: 24px;
            line-height: 24px;
            font-size: 12px;
            width: 68px;
            position: absolute;
            bottom: 0;
            left: 50%;
            margin-left: -34px;
            padding: 0;
        }
    }
    .good {
        background: #fafafa;
    }
}
