
.flex {
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
}
.flexwrap {
    display: -webkit-box;
    -webkit-box-lines: multiple;
    display: flex;
    flex-wrap: wrap;
}
.flex_r {
    @extend.flex;
    @extend .ai_fs;
    -webkit-box-direction: reverse;
    -webkit-box-pack: end;
    flex-direction: row-reverse;
}
.fd_r {
    -webkit-box-orient: horizontal;
    flex-direction: row;
}
.fd_rr {
    -webkit-box-direction: reverse;
    flex-direction: row-reverse;
}
.fd_c {
    -webkit-box-orient: vertical;
    flex-direction: column;
}
.fd_cr {
    -webkit-box-orient: vertical;
    -webkit-box-direction: reverse;
    flex-direction: column-reverse;
}
.jc_c {
    -webkit-box-pack: center;
    justify-content: center;
}
.jc_e {
    -webkit-box-pack: end;
    justify-content: flex-end;
}
.jc_sb {
    -webkit-box-pack: justify;
    justify-content: space-between;
}
.jc_sa {
    -webkit-box-pack: justify;
    justify-content: space-around;
}
.ai_c {
    -webkit-box-align: center;
    align-items: center;
}
.ai_s {
    align-items: stretch;
}
.ai_b {
    -webkit-box-align: baseline;
    align-items: baseline;
}
.ai_fs {
    -webkit-box-align: start;
    align-items: flex-start;
}
.ai_fe {
    -webkit-box-align: end;
    align-items: flex-end;
}
.as_c {
    align-self: center;
}
.as_s {
    align-self: stretch;
}
.as_fs {
    align-self: flex-start;
}
.as_fe {
    align-self: flex-end;
}

%flexavg,
%flex_0 {
    display: block;
    -webkit-box-flex: 1;
    flex: 1;
    width: 0px;
}
%flexitem,
%flex_1 {
    display: block;
    -webkit-box-flex: 1;
    flex: 1;
}
.fx_n {
    flex: none;
}
.fx_0 {
    flex: 0;
}
.fx_1 {
    flex: 1;
}
.fx_2 {
    flex: 2;
}
.fx_3 {
    flex: 3;
}
.fx_4 {
    flex: 4;
}

.flexavg,
.flex_avg,
.flex_0 {
    @extend %flexavg;
}
.flexitem,
.flex_1 {
    @extend %flexitem;
}

.of_h {
    overflow: hidden;
}
.of_v {
    overflow: visible;
}
.of_s {
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
}
.ofx_h {
    overflow-x: hidden;
}
.ofx_a {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}
.ofx_s {
    overflow-x: scroll;
    -webkit-overflow-scrolling: touch;
}
.ofy_h {
    overflow-y: hidden;
}
.ofy_a {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
}
.ofy_s {
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
}

.dp_f {
    display: flex;
}
.dp_b {
    display: block;
}
.dp_i {
    display: inline;
}
.dp_n {
    display: none;
}
.dp_ib {
    display: inline-block;
}

.vb_hd {
    visibility: hidden;
}

.clearfix {
    zoom: 1;
}
.clearfix:after {
    height: 0px;
    display: block;
    font-size: 0px;
    content: "";
    clear: both;
}
.fl_r {
    float: right;
}
.fl_l {
    float: left;
}
.cl_l {
    clear: left;
}
.cl_r {
    clear: right;
}
.cl_b {
    clear: both;
}

.ps_r {
    position: relative;
}
.ps_a {
    position: absolute;
}
.ps_f {
    position: fixed;
}
.ps_s {
    position: static;
}
.ps_c {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.va_t {
    vertical-align: top;
}
.va_m {
    vertical-align: middle;
}
.va_b {
    vertical-align: bottom;
}
.va_tb {
    vertical-align: text-bottom;
}
.ta_c {
    text-align: center;
}
.ta_r {
    text-align: right;
}
.ta_l {
    text-align: left;
}
.td_u {
    text-decoration: underline;
}

.to_e {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}
.to_e2,
.to_e3 {
    word-break: break-all;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}
.to_e2:before,
.to_e3:before {
    content: "";
}
.to_e3 {
    -webkit-line-clamp: 3;
}

.dock {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;
}
.dock_t {
    position: absolute;
    top: 0;
}
.dock_b {
    position: absolute;
    bottom: 0;
}
.dock_l {
    position: absolute;
    left: 0;
}
.dock_r {
    position: absolute;
    right: 0;
}
.dock_tb {
    position: absolute;
    top: 0;
    bottom: 0;
}
.dock_tl {
    position: absolute;
    top: 0;
    left: 0;
}
.dock_tlr {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
}
.dock_tr {
    position: absolute;
    top: 0;
    right: 0;
}
.dock_tbl {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
}
.dock_tbr {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
}
.dock_blr {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}
.dock_bl {
    position: absolute;
    bottom: 0;
    left: 0;
}
.dock_br {
    position: absolute;
    bottom: 0;
    right: 0;
}
.dock_lr {
    position: absolute;
    left: 0;
    right: 0;
}

.pd_s {
    padding: 5px;
}
.pd_m {
    padding: 10px;
}
.pd_l {
    padding: 15px;
}
.pd_xl {
    padding: 20px;
}
.pd_xxl {
    padding: 30px;
}

.pt_0 {
    padding-top: 0px;
}
.pt_1 {
    padding-top: 1px;
}
.pt_xs {
    padding-top: 3px;
}
.pt_s {
    padding-top: 5px;
}
.pt_6 {
    padding-top: 6px;
}
.pt_m {
    padding-top: 10px;
}
.pt_l {
    padding-top: 15px;
}
.pt_xl {
    padding-top: 20px;
}
.pt_xxl {
    padding-top: 30px;
}

.pt_sr {
    padding-top: 0.05rem;
}
.pt_6r {
    padding-top: 0.06rem;
}
.pt_8r {
    padding-top: 0.08rem;
}
.pt_mr {
    padding-top: 0.1rem;
}
.pt_lr {
    padding-top: 0.15rem;
}
.pt_xlr {
    padding-top: 0.2rem;
}
.pt_xxlr {
    padding-top: 0.3rem;
}

.pr_6r {
    padding-right: 0.06rem;
}
.pl_6r {
    padding-left: 0.06rem;
}
.pb_6r {
    padding-bottom: 0.06rem;
}
.pl_6r {
    padding-left: 0.06rem;
}
.pr_6r {
    padding-right: 0.06rem;
}

.pl_0 {
    padding-left: 0px;
}
.pl_s {
    padding-left: 5px;
}
.pl_m {
    padding-left: 10px;
}
.pl_l {
    padding-left: 15px;
}
.pl_xl {
    padding-left: 20px;
}
.pl_xxl {
    padding-left: 30px;
}

.pl_sr {
    padding-left: 0.05rem;
}
.pl_mr {
    padding-left: 0.1rem;
}
.pl_lr {
    padding-left: 0.15rem;
}
.pl_xlr {
    padding-left: 0.2rem;
}
.pl_xxlr {
    padding-left: 0.3rem;
}

.pr_0 {
    padding-right: 0px;
}
.pr_s {
    padding-right: 5px;
}
.pr_m {
    padding-right: 10px;
}
.pr_l {
    padding-right: 15px;
}
.pr_xl {
    padding-right: 20px;
}

.pr_sr {
    padding-right: 0.05rem;
}
.pr_mr {
    padding-right: 0.1rem;
}
.pr_lr {
    padding-right: 0.15rem;
}
.pr_xlr {
    padding-right: 0.2rem;
}
.pr_xxlr {
    padding-right: 0.3rem;
}

.pb_s {
    padding-bottom: 5px;
}
.pb_m {
    padding-bottom: 10px;
}
.pb_l {
    padding-bottom: 15px;
}
.pb_xl {
    padding-bottom: 20px;
}
.pb_xxl {
    padding-bottom: 30px;
}

.pb_sr {
    padding-bottom: 0.05rem;
}
.pb_8r {
    padding-bottom: 0.08rem;
}
.pb_mr {
    padding-bottom: 0.1rem;
}
.pb_lr {
    padding-bottom: 0.15rem;
}
.pb_xlr {
    padding-bottom: 0.2rem;
}
.pb_xxlr {
    padding-bottom: 0.3rem;
}

.mg_0 {
    margin: 0;
}
.mg_10r {
    margin: 0.1rem;
}
.mg_s {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
}
.mg_m {
    margin-left: 10px;
    margin-top: 10px;
    margin-right: 10px;
}
.mg_l {
    margin-left: 15px;
    margin-top: 15px;
    margin-right: 15px;
}
.mg_xl {
    margin-left: 20px;
    margin-top: 20px;
    margin-right: 20px;
}
.mg_a {
    margin: auto;
}

.mh_s {
    margin-left: 5px;
    margin-right: 5px;
}
.mh_m {
    margin-left: 10px;
    margin-right: 10px;
}
.mh_l {
    margin-left: 15px;
    margin-right: 15px;
}
.mh_xl {
    margin-left: 20px;
    margin-right: 20px;
}

.ml_1 {
    margin-left: 1px;
}
.ml_2 {
    margin-left: 2px;
}
.ml_3 {
    margin-left: 3px;
}
.ml_s {
    margin-left: 5px;
}
.ml_m {
    margin-left: 10px;
}
.ml_l {
    margin-left: 15px;
}
.ml_xl {
    margin-left: 20px;
}
.mr_1 {
    margin-right: 1px;
}
.mr_2 {
    margin-right: 2px;
}
.mr_3 {
    margin-right: 3px;
}
.mr_s {
    margin-right: 5px;
}
.mr_m {
    margin-right: 10px;
}
.mr_l {
    margin-right: 15px;
}
.mr_xl {
    margin-right: 20px;
}
.mt_xs {
    margin-top: 3px;
}
.mt_s {
    margin-top: 5px;
}
.mt_8 {
    margin-top: 8px;
}
.mt_m {
    margin-top: 10px;
}
.mt_l {
    margin-top: 15px;
}
.mt_xl {
    margin-top: 20px;
}
.mt_xxl {
    margin-top: 30px;
}
.mb_s {
    margin-bottom: 5px;
}
.mb_8 {
    margin-bottom: 8px;
}
.mb_m {
    margin-bottom: 10px;
}
.mb_l {
    margin-bottom: 15px;
}
.mb_xl {
    margin-bottom: 20px;
}
.mb_xxl {
    margin-bottom: 30px;
}

.mt_1 {
    margin-top: 1px;
}
.mt_2 {
    margin-top: 2px;
}
.mt_2r {
    margin-top: 0.2rem;
}
.mt_6r {
    margin-top: 0.06rem;
}
.mt_8r {
    margin-top: 0.08rem;
}
.mt_xsr {
    margin-top: 0.03rem;
}
.mt_sr {
    margin-top: 0.05rem;
}
.mt_mr {
    margin-top: 0.1rem;
}
.mt_lr {
    margin-top: 0.15rem;
}
.mt_xlr {
    margin-top: 0.2rem;
}
.mt_xxlr {
    margin-top: 0.3rem;
}

.mb_6r {
    margin-bottom: 0.06rem;
}
.mb_8r {
    margin-bottom: 0.08rem;
}
.ml_sr {
    margin-left: 0.05rem;
}
.ml_mr {
    margin-left: 0.1rem;
}
.ml_lr {
    margin-left: 0.15rem;
}
.mr_sr {
    margin-right: 0.05rem;
}
.mr_mr {
    margin-right: 0.1rem;
}
.mr_lr {
    margin-right: 0.15rem;
}

.mb_sr {
    margin-bottom: 0.05rem;
}
.mb_mr {
    margin-bottom: 0.1rem;
}
.mb_lr {
    margin-bottom: 0.15rem;
}
.mb_xlr {
    margin-bottom: 0.2rem;
}
.mb_xxlr {
    margin-bottom: 0.3rem;
}

.fs_0 {
    font-size: 0px;
}
.fs_xxs {
    font-size: 8px;
}
.fs_xxsr {
    font-size: 0.08rem;
}
.fs_xs {
    font-size: 10px;
}
.fs_xsr {
    font-size: 0.1rem;
}
.fs_s {
    font-size: 12px;
}
.fs_sr {
    font-size: 0.12rem;
}
.fs_m {
    font-size: 14px;
}
.fs_mr {
    font-size: 0.14rem;
}
.fs_l {
    font-size: 16px;
}
.fs_lr {
    font-size: 0.16rem;
}
.fs_xl {
    font-size: 18px;
}
.fs_xlr {
    font-size: 0.18rem;
}
.fs_20 {
    font-size: 20px;
}
.fs_22 {
    font-size: 22px;
}
.fs_xxl {
    font-size: 24px;
}
.fs_xxlr {
    font-size: 0.24rem;
}

.fs_15r {
    font-size: 0.15rem;
}

.fw_xs {
    font-weight: 100;
}
.fw_s,
.fw_l {
    font-weight: 200;
}
.fw_m,
.fw_n {
    font-weight: normal;
}
.fw_b {
    font-weight: bold;
}

.cl_fff {
    color: #fff;
}
.cl_aaa {
    color: #aaa;
}
.cl_bbb {
    color: #bbb;
}
.cl_ccc {
    color: #ccc;
}
.cl_999 {
    color: #999;
}
.cl_666 {
    color: #666;
}
.cl_333 {
    color: #333;
}
.cl_000 {
    color: #000;
}

.bg_non,
.bg_0000,
.transparent,
.bg_trans {
    background-color: rgba(0, 0, 0, 0);
}
.bg_fff {
    background-color: #fff;
}
.bg_f7 {
    background-color: #f7f7f7;
}
.bg_f2 {
    background-color: #f2f2f291;
}
.bg_eee {
    background-color: #eee;
}
.bg_ddd {
    background-color: #ddd;
}
.bg_ccc {
    background-color: #ccc;
}
.bg_000 {
    background-color: #000;
}
.bg_333 {
    background-color: #333;
}
.nobg {
    background: none !important;
}

.lh_xxs {
    line-height: 20px;
}
.lh_xs {
    line-height: 24px;
}
.lh_s {
    line-height: 34px;
}
.lh_m {
    line-height: 40px;
}
.lh_l {
    line-height: 50px;
}
.lh_xl {
    line-height: 60px;
}
.lh_bar {
    line-height: 44px;
}
.lh_0 {
    line-height: 0;
}
.lh_1 {
    line-height: 1;
}
.lh_1x {
    line-height: 100%;
}

.cir_d {
    width: 44px;
    height: 44px;
    border-radius: 50%;
    overflow: hidden;
}
.cir {
    border-radius: 50%;
    overflow: hidden;
}
.cir_xs {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
}
.cir_s {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    overflow: hidden;
}
.cir_m {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
}
.cir_l {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
}
.cir_xl {
    width: 60px;
    height: 60px;
    border-radius: 50%;
}
.cir_xxl {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
}

.sqr_d {
    width: 44px;
    height: 44px;
}
.sqr_s {
    width: 33px;
    height: 33px;
}
.sqr_35 {
    width: 35px;
    height: 35px;
}
.sqr_m {
    width: 40px;
    height: 40px;
}
.sqr_44 {
    width: 44px;
    height: 44px;
}
.sqr_l {
    width: 50px;
    height: 50px;
}
.sqr_xl {
    width: 60px;
    height: 60px;
}
.sqr_xxl {
    width: 90px;
    height: 90px;
}

.zi_1,
.z_1 {
    z-index: 1;
}
.zi_2,
.z_2 {
    z-index: 2;
}
.zi_3,
.z_3 {
    z-index: 3;
}
.z_1000 {
    z-index: 1000;
}

.h_xs {
    height: 24px;
}
.h_xsr {
    height: 0.24rem;
}
.h_s {
    height: 34px;
}
.h_sr {
    height: 0.34rem;
}
.h_m {
    height: 40px;
}
.h_mr {
    height: 0.4rem;
}
.h_l {
    height: 50px;
}
.h_lr {
    height: 0.5rem;
}
.h_xl {
    height: 60px;
}
.h_xlr {
    height: 0.6rem;
}
.h_bar {
    height: 44px;
}
.h_full,
.h_1x {
    height: 100%;
}
.h_25 {
    height: 25px;
}
.h_25r {
    height: 0.25rem;
}
.h_30 {
    height: 0.3rem;
}
.h_30r {
    height: 0.3rem;
}

.mh_90 {
    min-height: 90px;
}

.w_0 {
    width: 0px;
}
.w_s {
    width: 30px;
}
.w_m {
    width: 40px;
}
.w_l {
    width: 50px;
}
.w_xl {
    width: 60px;
}
.w_bar {
    width: 44px;
}
.w_full,
.w_1x {
    width: 100%;
}

.rot_45 {
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
}
.rot_90 {
    -webkit-transform: rotate(90deg);
    transform: rotate(90deg);
}
.rot_135 {
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
}
.rot_180 {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
}
.rot_225 {
    -webkit-transform: rotate(225deg);
    transform: rotate(225deg);
}
.rot_270 {
    -webkit-transform: rotate(270deg);
    transform: rotate(270deg);
}
.rot-45 {
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
}
.rot-90 {
    -webkit-transform: rotate(-90deg);
    transform: rotate(-90deg);
}

.br_2 {
    border-radius: 2px;
}
.br_4 {
    border-radius: 4px;
}
.br_6 {
    border-radius: 6px;
}

.bd_b,
.bd_t,
.bd_r,
.bd_l,
.bd_tl,
.bd_tr,
.bd_tbr,
.bd_tbl,
.bd_tb,
.bd_blr,
.bd_bl,
.bd_br,
.bd_lr,
.bd_s,
.bd_a,
.bd_c {
    position: relative;
}
.bd_b:after,
.bd_t:after,
.bd_r:after,
.bd_l:after,
.bd_tl:after,
.bd_tr:after,
.bd_tbr:after,
.bd_tbl:after,
.bd_tb:after,
.bd_blr:after,
.bd_bl:after,
.bd_br:after,
.bd_lr:after,
.bd_s:after,
.bd_a:after,
.bd_c:after {
    @include halfPixelBorder(#ddd, 0);
}
.bd_b:after {
    border-top-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}
.bd_t:after {
    border-bottom-width: 0;
    border-left-width: 0;
    border-right-width: 0;
}
.bd_l:after {
    border-bottom-width: 0;
    border-top-width: 0;
    border-right-width: 0;
}
.bd_r:after {
    border-bottom-width: 0;
    border-top-width: 0;
    border-left-width: 0;
}
.bd_tb:after {
    border-left-width: 0;
    border-right-width: 0;
}
.bd_tbr:after {
    border-left-width: 0;
}
.bd_tbl:after {
    border-right-width: 0;
}
.bd_tr:after {
    border-bottom-width: 0;
    border-left-width: 0;
}
.bd_tl:after {
    border-bottom-width: 0;
    border-right-width: 0;
}
.bd_blr:after {
    border-top-width: 0;
}
.bd_bl:after {
    border-top-width: 0;
    border-right-width: 0;
}
.bd_br:after {
    border-top-width: 0;
    border-left-width: 0;
}
.bd_lr:after {
    border-top-width: 0;
    border-bottom-width: 0;
}
.bd_c:after {
    right: 0;
    border-bottom-width: 0;
    border-top-width: 0;
    border-left-width: 0;
}
.bd_0 {
    border: 0;
}

.t_3 {
    transition: all 300ms ease-out 0ms;
}
.t_2 {
    transition: all 200ms ease-out 0ms;
}

.op_0 {
    opacity: 0;
}
