@mixin baseHalfBorder {
    position: relative;

    &::after {
        position: absolute;
        content: "";
        display: block;
        top: 0;
        right: -100%;
        left: 0;
        bottom: -100%;
        pointer-events: none;
        z-index: 1;
        transform: scale(0.5);
        transform-origin: 0% 0%;
    }
}

/**
   * 半像素边框
   */
@mixin halfPixelBorder($color: #aaa, $radius: 0px, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border: 1px $type $color;
        border-radius: $radius * 2;
    }
}

/**
   * 半像素上边框
   */
@mixin halfPixelBorderTop($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-top: 1px $type $color;
    }
}

/**
   * 半像素下边框
   */
@mixin halfPixelBorderBottom($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-bottom: 1px $type $color;
    }
}

/**
   * 半像素右边框
   */
@mixin halfPixelBorderRight($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-right: 1px $type $color;
    }
}

@mixin halfPixelBorderLeft($color: #aaa, $type: solid) {
    @include baseHalfBorder;

    &::after {
        border-left: 1px $type $color;
        transform: scale(0.5, 1);
    }
}

@mixin arrow($color: #fff, $size: 10px, $border: 2px) {
    position: relative;
    border-top: $border solid $color;
    border-right: $border solid $color;
    width: $size;
    height: $size;
    font-size: 0px;
    vertical-align: middle;
    -webkit-transform: rotate(45deg);
    -webkit-transform-origin: 50% 50%;
    display: inline-block;
}

@mixin ellipsis() {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;
}

@mixin ellipsis-line($line: 2) {
    /* stylelint-disable */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    /* stylelint-enable */
    -webkit-line-clamp: $line;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
}

@import '../../../../node_modules/pajk-design/es/style/themes/default.scss';
.m-home-main {
    &::after {
        content: "";
        display: block;
        height: 66px;
    }
    &::before {
        content: "";
        position: absolute;
        width: 100%;
        display: block;
        height: 231px;
        z-index: 0;
        background-image: linear-gradient(
            180deg,
            #ffffff 65%,
            #f6f6f6 75%,
            #f6f6f6 99%
        );
    }
    > * {
        position: relative;
        z-index: 1;
    }
}

.with-mask {
    &::after {
        content: " ";
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.03);
        z-index: 1;
        border-radius: 8px;
    }
}
